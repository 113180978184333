import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-5 xl-3" }
const _hoisted_2 = { class: "card-body border-top p-9" }
const _hoisted_3 = { class: "d-flex flex-stack" }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { class: "nav nav-tabs nav-line-tabs mb-5 fs-5" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "card mb-5 mb-xl-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditSchool = _resolveComponent("EditSchool")!
  const _component_dokumenpks = _resolveComponent("dokumenpks")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("ul", _hoisted_5, [
              _createElementVNode("li", _hoisted_6, [
                _createElementVNode("a", {
                  class: "nav-link active fs-5 text-dark text-hover-primary fw-bolder text-active-primary me-6",
                  "data-bs-toggle": "tab",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.infoSekolah())),
                  style: {"cursor":"pointer"}
                }, "Form Informasi Sekolah")
              ]),
              _createElementVNode("li", _hoisted_7, [
                _createElementVNode("a", {
                  class: "nav-link fs-5 text-dark text-hover-primary fw-bolder text-active-primary me-6",
                  "data-bs-toggle": "tab",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Dookumenpks())),
                  style: {"cursor":"pointer"}
                }, "Dokumen Sekolah")
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.items.infoSekolah === true)
        ? (_openBlock(), _createBlock(_component_EditSchool, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.items.Dookumenpks === true)
        ? (_openBlock(), _createBlock(_component_dokumenpks, { key: 1 }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}