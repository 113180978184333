
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import EditSchool from "@/components/1crm/1sekolah/sekolah/edit-sekolah.vue";
import Dokumenpks from "@/components/1crm/1sekolah/sekolah/dokumen-pks.vue";

interface items {
  infoSekolah: Boolean;
  Dookumenpks: Boolean;
}

export default defineComponent({
  name: "Edit Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Dokumenpks,
    EditSchool,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    let items = reactive<items>({
      infoSekolah: true,
      Dookumenpks: false,
    });

    const infoSekolah = () => {
      items.infoSekolah = true;
      items.Dookumenpks = false;
    };
    const Dookumenpks = () => {
      items.infoSekolah = false;
      items.Dookumenpks = true;
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);
      setCurrentPageBreadcrumbs("Ubah Data Sekolah", ["Data"]);
    });

    return {
      submitButton,
      infoSekolah,
      Dookumenpks,
      items,
    };
  },
});
